export default {
  async insertMovLlaveroSalida (Vue, idllavero, idempleado, idmotivoSalida, fecha) {
    await Vue.$api.call(
      'movLlavero.insert',
      {
        values: {
          idllavero,
          idempleado,
          fecha,
          idmotivo_salida_llavero: idmotivoSalida,
        }
      }
    )
  },
  async updateLlavero (Vue, idllavero, estado) {
    await Vue.$api.call('llavero.update', { values: { idllavero, estado } })
  }
}
